import { Button, Modal, Input, Form, Tag, message } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import customAxios from "../../utils/axios";

import { EditOutlined } from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const CarnalSettings = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      bot_offline: "",
      country_blocked: "",
      user_banned: "",
      no_balance: "",
      start_message: "",
    },
  ]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const formItemLayout = null;

  const columns = [
    {
      title: "Carnal New User Start Tokens",
      description: "Set starter tokens amount for new carnal users",
      dataIndex: "user_start_token",
      key: "user_start_token",
      render: (text) => <Tag>{text} tokens</Tag>,
    },
    {
      title: "Carnal Chat Answer Delay",
      description: "Delay before AI will start typing of the text",
      dataIndex: "chat_answer_delay_from",
      key: "chat_answer_delay_from",
      render: (text, values) => (
        <>
          From <Tag>{text} sec.</Tag> to{" "}
          <Tag>{values.chat_answer_delay_to} sec.</Tag>
        </>
      ),
    },
    {
      title: "Carnal Chat Typing Delay",
      description: "Duration of typing message from AI",
      dataIndex: "chat_typing_delay_from",
      key: "chat_typing_delay_from",
      render: (text, values) => (
        <>
          From <Tag>{text} sec.</Tag> to{" "}
          <Tag>{values.chat_typing_delay_to} sec.</Tag>
        </>
      ),
    },
    {
        title: "Subscription Cancel Link",
        description: "Set the cancelation link",
        dataIndex: "cancel_link",
        key: "cancel_link",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Subscription Link Domain",
        description: "Set the NATS upsellplus <linkdomain>",
        dataIndex: "link_domain_subscription",
        key: "link_domain_subscription",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Subscription SiteID Domain",
        description: "Set the NATS upsellplus <SiteID>",
        dataIndex: "site_id_subscription",
        key: "site_id_subscription",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Tokens Link Domain",
        description: "Set the NATS tokenplus <linkdomain>",
        dataIndex: "link_domain_tokens",
        key: "link_domain_tokens",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Tokens SiteID Domain",
        description: "Set the NATS tokenplus <SiteID>",
        dataIndex: "site_id_tokens",
        key: "site_id_tokens",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Terms of Service Link",
        description: "Set the Terms of Service link",
        dataIndex: "terms_of_service_link",
        key: "terms_of_service_link",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Privacy Policy Link",
        description: "Set the Privacy Policy link",
        dataIndex: "privacy_policy_link",
        key: "privacy_policy_link",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Platform Information Title",
        description: "Set the platform information title",
        dataIndex: "platform_info_title",
        key: "platform_info_title",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Platform Information Content",
        description: "Set the platform information content",
        dataIndex: "platform_info_content",
        key: "platform_info_content",
        render: (text) => <Tag>{text}</Tag>,
      },
  ];

  function editDetail(record) {
    form.setFieldsValue({
      user_start_token: record.user_start_token,
      chat_answer_delay_from: record.chat_answer_delay_from,
      chat_answer_delay_to: record.chat_answer_delay_to,
      chat_typing_delay_from: record.chat_typing_delay_from,
      chat_typing_delay_to: record.chat_typing_delay_to,
      cancel_link: record.cancel_link,
      link_domain_subscription: record.link_domain_subscription,
      site_id_subscription: record.site_id_subscription,
      link_domain_tokens: record.link_domain_tokens,
      site_id_tokens: record.site_id_tokens,
      terms_of_service_link: record.terms_of_service_link,
      privacy_policy_link: record.privacy_policy_link,
      platform_info_title: record.platform_info_title,
      platform_info_content: record.platform_info_content
    });
    showEditMsgModal();
  }

  useEffect(() => {
    getMsgData();
  }, []);

  const getMsgData = () => {
    customAxios
      .get(SERVER_URL + "/system/settings-carnal")
      .then(function (res) {
        setData(res.data);
        setLoading(false);
      });
  };

  const showEditMsgModal = () => {
    setIsEditModalOpen(true);
  };

  const handleMsgOk = async () => {
    try {
      await form.validateFields();
      const messages = form.getFieldsValue();
      const { data } = await customAxios.put(
        SERVER_URL + "/system/settings-carnal",
        {
          ...messages,
        }
      );
      setData(data);
      setIsEditModalOpen(false);
      clearFormData();

      messageApi.open({
        type: "success",
        content: "Updated successfully",
      });
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };
  const handleUserCancel = () => {
    setIsEditModalOpen(false);
    clearFormData();
  };
  function clearFormData() {
    form.resetFields();
  }

  return (
    <div>
      {contextHolder}
      <div></div>
      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {columns.map((column) => (
            <div
              key={column.key}
              style={{
                margin: "10px",
                padding: "10px",
                border: "1px solid gray",
              }}
            >
              <h3>{column.title}</h3>
              <p>
                <i>{column.description}</i>
              </p>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {data.map((item) => (
                  <li key={item.id}>
                    {column.render(item[column.dataIndex], item)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div style={{ margin: "10px", padding: "10px" }}>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li style={{ margin: "5px" }}>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    editDetail(data[0]);
                  }}
                >
                  <EditOutlined />
                </Button>
              </li>
            </ul>
          </div>
        </div>
      )}

      <Modal
        title={"Edit carnal settings"}
        open={isEditModalOpen}
        onOk={handleMsgOk}
        onCancel={handleUserCancel}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="user_start_token"
            label="Carnal New User Start Tokens"
          >
            <Input
              type="number"
              placeholder=""
              onInput={(event) => {
                const value = event.target.value;
                if (value.includes("-")) {
                  event.target.value = 0;
                }
              }}
            />
          </Form.Item>{" "}
          <Form.Item
            {...formItemLayout}
            name="chat_answer_delay_from"
            label="Carnal Chat Answer Delay From"
            rules={[
              {
                required: true,
                message: "Please add Chat Answer Delay From",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const maxValue = form.getFieldValue("chat_answer_delay_to");
                  return parseInt(value, 10) <= maxValue
                    ? Promise.resolve()
                    : Promise.reject(
                        "Answer Delay From should be equal or less than Answer Delay To"
                      );
                },
              },
            ]}
          >
            <Input type="number" placeholder="" />
          </Form.Item>{" "}
          <Form.Item
            {...formItemLayout}
            name="chat_answer_delay_to"
            label="Carnal Chat Answer Delay To"
            rules={[
              {
                required: true,
                message: "Please add Chat Answer Delay To",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const minValue = form.getFieldValue("chat_answer_delay_from");
                  return parseInt(value, 10) >= minValue
                    ? Promise.resolve()
                    : Promise.reject(
                        "Answer Delay To should be equal or more than Answer Delay From"
                      );
                },
              },
            ]}
          >
            <Input type="number" placeholder="" />
          </Form.Item>{" "}
          <Form.Item
            {...formItemLayout}
            name="chat_typing_delay_from"
            label="Carnal Chat Typing Delay From"
            rules={[
              {
                required: true,
                message: "Please add Chat Typing Delay From",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const maxValue = form.getFieldValue("chat_typing_delay_to");
                  return parseInt(value, 10) <= maxValue
                    ? Promise.resolve()
                    : Promise.reject(
                        "Typing Delay From should be equal or less than Typing Delay To"
                      );
                },
              },
            ]}
          >
            <Input type="number" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="chat_typing_delay_to"
            label="Carnal Chat Typing Delay To"
            rules={[
              {
                required: true,
                message: "Please add Chat Typing Delay To",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const minValue = form.getFieldValue("chat_typing_delay_from");
                  return parseInt(value, 10) >= minValue
                    ? Promise.resolve()
                    : Promise.reject(
                        "Typing Delay To should be equal or more than Typing Delay From"
                      );
                },
              },
            ]}
          >
            <Input type="number" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="cancel_link"
            label="Subscription Cancel Link"
            rules={[
              {
                required: true,
                message: "Set the cancelation link",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="link_domain_subscription"
            label="Subscription Link Domain"
            rules={[
              {
                required: true,
                message: "Set the NATS upsellplus <linkdomain>",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="site_id_subscription"
            label="Subscription SiteID Domain"
            rules={[
              {
                required: true,
                message: "Set the NATS upsellplus <SiteID>",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="link_domain_tokens"
            label="Tokens Link Domain"
            rules={[
              {
                required: true,
                message: "Set the NATS tokenplus <linkdomain>",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="site_id_tokens"
            label="Tokens SiteID Domain"
            rules={[
              {
                required: true,
                message: "Set the NATS tokenplus <SiteID>",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="terms_of_service_link"
            label="Terms of Service Link"
            rules={[
              {
                required: true,
                message: "Set the Terms of Service link",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="privacy_policy_link"
            label="Privacy Policy Link"
            rules={[
              {
                required: true,
                message: "Set the Privacy Policy link",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="platform_info_title"
            label="Platform Information Title"
            rules={[
              {
                required: true,
                message: "Set the platform information title",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="platform_info_content"
            label="Platform Information Content"
            rules={[
              {
                required: true,
                message: "Set the platform information content",
              },
              {
                validator: (_, value) =>
                  value.length >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be empty"),
              },
            ]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CarnalSettings;
